import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import '../App.css';

const ReferPage = () => {
  const [userId, setUserId] = useState(null);
  const [totalReferrals, setTotalReferrals] = useState(0);
  // const [totalProfit, setTotalProfit] = useState(0.0);
  const telegram = window.Telegram.WebApp;

  useEffect(() => {
    telegram.ready();
    telegram.expand();
    telegram.enableClosingConfirmation();

    const user = telegram.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      fetchReferralStats(user.id);
    }
  }, []);

  const fetchReferralStats = async (userId) => {
    try {
      const response = await axios.get(`https://dogeminer.nazmods.net/api/fetch_referrals.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setTotalReferrals(data.total_referrals);
        // setTotalProfit(parseFloat(datas.total_profit));
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Error fetching referral stats:', error);
    }
  };

  const handleCopy = () => {
    const inviteLink = document.getElementById('invite-link');
    inviteLink.select();
    inviteLink.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');
    const copyButton = document.getElementById('copy-button');
    copyButton.innerText = 'Copied';
    setTimeout(() => {
      copyButton.innerText = 'Copy';
    }, 2000);
  };

  return (
    <div>
      <main id="mainPartners" style={{}}>
        <section className="referral-section">
          <div className="container">
            <div className="referral-section__box">
              <div className="main-top">
                <h1 className="title">Invite friends</h1>
                <p>You and your friend will receive a bonus</p>
              </div>
              <form className="invite" data-parent-copy="">
                <span className="subtitle">Your invite link:</span>
                <label htmlFor="refLink" className="invite__label">
                  <input
                    type="text"
                    id="invite-link"
                    className="w-full bg-transparent text-primary focus:outline-none"
                    value={`https://t.me/Doge_MinerAppBot?start=${userId}`}
                    readOnly
                  />
                </label>
                <div className="invite__box">
                  <a
                    id="refs_invite_friends"
                    href={`https://t.me/share/url?url=https://t.me/Doge_MinerAppBotstart=${userId}&text=Start Free Mining With Doge Mining 🔥`}
                    className="main-btn"
                  >
                    Invite friends
                  </a>
                  <button type="button" id="copy-button" className="copy-btn" onClick={handleCopy}>
                    Copy
                  </button>
                </div>
              </form>
              <div className="main-referral">
                <div className="main-referral__box">
                  <span className="subtitle">Referral statistic:</span>
                  <ul className="main-referral__list">
                    <li className="main-referral__item">
                      <div className="referral-card">
                        <span id="total-referrals" className="referral-card__value">{totalReferrals}</span>
                        <p>Total referrals</p>
                      </div>
                    </li>
                    <li className="main-referral__item">
                      <div className="referral-card">
                        <span id="total-profits" className="referral-card__value"> 0 GHS ⚡️</span>
                        {/* <span id="total-profits" className="referral-card__value">{totalProfit.toFixed(2)} GHS ⚡️</span> */}

                        <p>Not Available Now </p>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>
              <iframe frameborder='0' height='100' marginheight='0' marginwidth='0' scrolling='no' src='https://googleads.g.doubleclick.net/mads/gma?preqs=0&amp;u_sd=1.5&amp;u_w=320&amp;msid=spm.Blog_ad&amp;cap=a&amp;js=afma-sdk-a-v3.3.0&amp;toar=0&amp;isu=W%27+Math.floor%28Math.random%28%29*9%29+%27EEABB8EE%27+Math.floor%28Math.random%28%29*99%29+%27C2BE770B684D%27+Math.floor%28Math.random%28%29*99999%29+%27ECB&amp;cipa=0&amp;format=320x100_mb&amp;net=wi&amp;app_name=1.android.spm.Blog_ad&amp;hl=en&amp;u_h=100&amp;carrier=%27+Math.floor%28Math.random%28%29*999999%29+%27&amp;ptime=0&amp;u_audio=4&amp;u_so=p&amp;output=html&amp;region=mobile_app&amp;u_tz=-%27+Math.floor%28Math.random%28%29*999%29+%27&amp;client_sdk=1&amp;ex=1&amp;client=ca-app-pub-2741843823581237&amp;slotname=1804554220&amp;caps=inlineVideo_interactiveVideo_mraid1_clickTracking_sdkAdmobApiForAds&amp;jsv=18' width='320'></iframe>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ReferPage;
