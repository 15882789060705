import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import Footer from '../components/Footer';

function WalletPage() {
  const [balance, setBalance] = useState(0.0);
  const [userId, setUserId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();

    const user = tg.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      fetchBalance(user.id);
      fetchTransactions(user.id);
    }
  }, []);

  const fetchBalance = async (userId) => {
    try {
      const response = await axios.get(`https://dogeminer.nazmods.net/api/get_user.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setBalance(parseFloat(data.user.balance));
      } else {
        setToastMessage(data.message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const fetchTransactions = async (userId) => {
    try {
      const response = await axios.get(`https://dogeminer.nazmods.net/api/fetch_withdrawals.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setTransactions(data.withdrawals);
        if (data.withdrawals.length === 0) {
          setToastMessage('No transactions found.');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        }
      } else {
        setToastMessage(data.message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  return (
    <div>
      <main id="mainWallet">
        <section className="wallet-section">
          <div className="container">
            <div className="wallet-section__box">
              <div className="main-info main-info--mode">
                <div className="main-info__inner">
                  <img width="40" height="40" src="/assets/coin.png" alt="image" />
                  <span className="main-info__value"><b id="balance">{balance.toFixed(8)}</b> DOGE</span>
                </div>
                <a className="main-btn" href="/withdraw">Withdraw</a>
                <p>Withdrawal takes up to 24 hours. On average 1-2 hours.</p>
              </div>

              <table className="history-list history-list--wallet w-full text-center">
                <thead>
                  <tr className="history-list__row history-list__row--head">
                    <th className="history-list__name">Date</th>
                    <th className="history-list__name">Coin</th>
                    <th className="history-list__name">Amount</th>
                    <th className="history-list__name">Type</th>
                    <th className="history-list__name">Status</th>
                  </tr>
                </thead>
                <tbody id="transactions">
                  {transactions.length > 0 ? (
                    transactions.map((withdrawal, index) => (
                      <tr key={index} className="transaction-item" style={{ color: 'black' }}>
                        <td>{new Date(withdrawal.timestamp).toLocaleDateString()}</td>
                        <td>DOGE</td>
                        <td>{(withdrawal.amount / 1e8).toFixed(8)}</td>
                        <td>Withdraw</td>
                        <td><span className="success-tick">Paid✅</span></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        {/* <img src="/assets/no-transaction.png" alt="No Transactions" className="mx-auto mb-2" /> */}
                        <p>No transaction yet...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {showToast && (
        <div className="toast">
          {toastMessage}
        </div>
      )}
    </div>
  );
}

export default WalletPage;
