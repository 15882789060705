import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import Footer from '../components/Footer';

const WithdrawPage = () => {
  const [userId, setUserId] = useState(null);
  const [balance, setBalance] = useState(0.0);
  const [amount, setAmount] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();

    const user = tg.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      fetchBalance(user.id);
    }
  }, []);

  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const fetchBalance = async (userId) => {
    try {
      const response = await axios.get(`https://dogeminer.nazmods.net/api/get_user.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setBalance(parseFloat(data.user.balance));
      } else {
        showToastMessage(data.message);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const handleWithdraw = async () => {
    if (!amount) {
      showToastMessage('Enter Amount');
      return;
    }

    if (!email) {
      showToastMessage('Enter Email');
      return;
    }

    const parsedAmount = parseFloat(amount);
    if (parsedAmount < 0.00001) {
      showToastMessage('Minimum withdrawal amount is 0.00001000 DOGE');
      return;
    }

    if (parsedAmount > balance) {
      showToastMessage('Insufficient balance');
      return;
    }

    const amountInSatoshis = Math.round(parsedAmount * 1e8); // Convert to satoshis

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://dogeminer.nazmods.net/api/process_withdraw.php', {
        user_id: userId,
        amount: amountInSatoshis,
        email: email
      });

      const data = response.data;
      if (data.success) {
        showToastMessage('Withdrawal Successful');
        setTimeout(() => {
          window.location.href = "/wallet";
        }, 100);
      } else {
        showToastMessage(data.message);
      }
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      showToastMessage('An error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-primary flex flex-col justify-between min-h-screen">
      <main id="mainWithdraw" className="flex flex-col items-center justify-between min-h-screen w-full">
        <section className="start-section flex flex-col items-center w-full mt-4 p-4">
          <div className="container mx-4 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
            <div className="">
              <div className="main-info">
                <div className="main-info__balance mb-4">
                  <label className="block text-primary text-sm font-bold mb-2">Current Balance:</label>
                  <div className="flex items-center justify-center  rounded px-4 py-2 mb-4">
                    <img width="24" height="24" src="/assets/coin.png" alt="Coin" className="mr-2" />
                    <span id="balance" className="text-primary text-xl font-bold">{balance.toFixed(8)}</span>
                  </div>
                </div>

                <div className="main-info__inner mb-4">
                  <label htmlFor="amount" className="block text-primary text-sm font-bold mb-2"></label>
                  <div className="flex items-center bg-input rounded px-6 py-2 mb-4">
                    <img width="24" height="24" src="/assets/coin.png" alt="Coin" className="mr-2" />
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      step="0.00000001"
                      min="0.0000001"
                      placeholder="Enter Amount Min 0.00001"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full bg-transparent text-primary focus:outline-none"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div className="main-info__inner mb-4">
                  <label htmlFor="email" className="block text-primary text-sm font-bold mb-2"></label>
                  <div className="flex items-center bg-input rounded px-4 py-2 mb-4">
                    <img width="24" height="24" src="/assets/faucet.png" alt="Coin" className="mr-2" />
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="FaucetPay Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full bg-input rounded px-2 py-2 text-primary focus:outline-none"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div className="main-info__btns flex justify-center mt-4">
                  <button
                    id="withdraw-btn"
                    className={`main-btn bg-withdraw hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded text-center w-full ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleWithdraw}
                    disabled={isSubmitting}
                  >
                    Withdraw
                  </button>
                </div>
                <div className="withdraw-info text-sm text-primary mt-2">
                  Min withdraw: 0.00001000 DOGE
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <a href="https://t.me/DogeMinerPaid">Check Recent Payments</a>

        <Footer />
      </main>

      {showToast && (
        <div className="toast">
          {toastMessage}
        </div>
      )}
    </div>
  );
};

export default WithdrawPage;
