import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import Footer from '../components/Footer';

function Homepage() {
  const [balance, setBalance] = useState(0.0);
  const [speed, setSpeed] = useState(0.0);
  const [userId, setUserId] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);  // State to track if user is blocked
  const [miningInterval, setMiningInterval] = useState(null);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();

    const user = tg.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      fetchUserDetails(user.id);
    }
  }, []);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://dogeminer.nazmods.net/api/get_user.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setBalance(parseFloat(data.user.balance));
        setSpeed(parseFloat(data.user.speed));
        setIsBlocked(data.user.is_blocked === 1);  // Check block status
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (userId !== null && !isBlocked) {
      if (miningInterval) {
        clearInterval(miningInterval);
      }
      const interval = setInterval(() => {
        setBalance(prevBalance => {
          const newBalance = prevBalance + speed * 0.00000001;
          updateBalance(userId, newBalance);
          return newBalance;
        });
      }, 1000);
      setMiningInterval(interval);
    }
  }, [userId, speed, isBlocked]);

  const updateBalance = async (userId, newBalance) => {
    try {
      await axios.post('https://dogeminer.nazmods.net/api/update_balance.php', {
        user_id: userId,
        balance: newBalance.toFixed(8),
      });
    } catch (error) {
      console.error('Error updating balance:', error);
    }
  };

  if (isBlocked) {
    return (
      <div className="blocked-view">
        <h1>You are blocked from using this service.</h1>
      </div>
    );
  }

  return (
    <div>
      <main id="mainMining" style={{ dataType: "doge" }}>
        <section className="start-section">
          <div className="container">
            <div className="start-section__box">
              <div className="main-info">
                <div className="main-info__inner">
                  <img width="40" height="40" src="/assets/coin.png" alt="image" />
                  <span className="main-info__value"><b id="balance">{balance.toFixed(8)}</b> DOGE</span>
                </div>
                <div className="main-info__mining">
                  <img src="/assets/icon.png" alt="" style={{ height: "24px", width: "24px", borderRadius: "50%" }} />
                  <span className="main-info__currency"><b id="speed">{speed.toFixed(2)}</b> TH/s</span>
                </div>
                <div className="main-info__btns">
                  <a className="main-btn main-btn--mode" href="/withdraw">Claim</a>
                </div>
              </div>
              <div className="mining">
                <center>
                  <img height="100px" width="600" src="/assets/miner.gif" alt="" />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Homepage;
