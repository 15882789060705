// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WalletPage from './pages/WalletPage';
import ReferPage from './pages/ReferPage';
import TaskPage from './pages/TaskPage';
import Footer from './components/Footer';
import WithdrawPage from './pages/WithdrawPage';
// import VisitSites from './pages/VisitSites';
import './App.css';
import AdsPage from './pages/AdPage';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/refer" element={<ReferPage />} />
          <Route path="/task" element={<TaskPage />} />
          <Route path="/withdraw" element={<WithdrawPage />} />
          {/* <Route path="/Visit" element={<VisitSites />} /> */}
          <Route path="/ad" element={<AdsPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
