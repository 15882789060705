import React, { useEffect, useState, useCallback } from 'react';
import Footer from '../components/Footer';
import axios from 'axios';
import '../App.css';
import { useAdsgram } from '../hooks/useAdsgram';

const AdsPage = () => {
    const [userId, setUserId] = useState(null);
    const [balance, setBalance] = useState(0.0);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        tg.expand();
        tg.enableClosingConfirmation();

        const user = tg.initDataUnsafe.user;
        if (user && user.id) {
            setUserId(user.id);
            fetchUserBalance(user.id);
        }
    }, []);

    const fetchUserBalance = async (userId) => {
        try {
            const response = await axios.get(`https://dogeminer.nazmods.net/api/get_user.php?telegram_user_id=${userId}`);
            const data = response.data;
            if (data.success) {
                setBalance(parseFloat(data.user.balance));
            } else {
                alert('Failed to retrieve user balance.');
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    const updateUserBalance = useCallback(async () => {
        try {
            const response = await axios.post('https://dogeminer.nazmods.net/api/ads_rewards.php', {
                telegram_user_id: userId,
            });
            const data = response.data;
            if (data.success) {
                fetchUserBalance(userId);
                setToastMessage('Congrats! You received +0.000100000 DOGE on your balance');
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 3000);
            } else {
                alert('Failed to update user balance.');
            }
        } catch (error) {
            console.error('Error updating user balance:', error);
        }
    }, [userId]);

    const handleAdCompletion = useCallback(() => {
        updateUserBalance();
    }, [updateUserBalance]);

    const handleAdError = useCallback((result) => {
        console.error('Ad error:', result.description || result);
        alert(`Failed to load ad: ${result.description || 'Unknown error'}`);
    }, []);

    const showAd = useAdsgram({
        blockId: "1374",
        onReward: handleAdCompletion,
        onError: handleAdError,
    });

    return (
        <div>
            <main id="mainAds" className="flex flex-col items-center justify-between min-h-screen w-full">
                <section className="start-section flex flex-col items-center w-full mt-4 p-4">
                    <div className="container mx-4 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
                        <div className="start-section__box p-6 text-center">
                            <div className="main-info">
                                <div className="main-info__inner mb-4">
                                    <div className="flex items-center justify-center bg-white rounded px-4 py-2 mb-4">
                                        <img className="mr-2" src="/assets/coin.png" alt="Coin" width="24" height="24" />
                                        <span id="balance" className="text-primary text-xl font-bold">{balance.toFixed(8)} DOGE</span>
                                    </div>
                                </div>
                                <div className="main-info__inner mb-4">
                                    <span id="username" className="block text-primary text-sm font-bold mb-2">Watch ads and get Paid In DOGE</span>
                                </div>
                                <div className="main-info__btns flex justify-center mt-4">
                                    <button id="watchAd" className="main-btn bg-yellow-600 hover:bg-black-600 text-white font-bold py-2 px-6 rounded text-center w-full" onClick={showAd}>Watch Ads</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
            {showToast && (
                <div id="successModal" className="toast show">
                    {toastMessage}
                </div>
            )}
        </div>
    );
};

export default AdsPage;
