// src/hooks/useAdsgram.js
import { useCallback, useEffect, useRef } from 'react';

export function useAdsgram({ blockId, onReward, onError }) {
  const AdControllerRef = useRef(undefined);

  useEffect(() => {
    if (window.Adsgram) {
      AdControllerRef.current = window.Adsgram.init({ blockId });
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [blockId, onError]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      try {
        await AdControllerRef.current.show();
        onReward();
      } catch (result) {
        onError(result);
      }
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [onError, onReward]);
}
